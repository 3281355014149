import {
  Divider,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/data/rutas';

const items = [
  {
    name: 'General',
    url: '/configuration',
  },
  {
    name: 'Usuarios',
    url: '/users',
  },
  {
    name: 'Usuarios Portal Cliente',
    url: '/users-clients',
  },
  {
    name: 'Tipos de vehículo',
    url: '/configuration-vehicles-type',
  },
  {
    name: 'Marcas de vehículo',
    url: rutas.vehicles_brands.slug,
  },
  {
    name: 'Modelos de vehículo',
    url: '/model/vehicles',
  },
  {
    name: 'Colores de vehículo',
    url: '/color-vehicles',
  },
  {
    name: 'Tipos de motor',
    url: '/motor-type',
  },
  {
    name: 'Categorías de soporte',
    url: '/subsoporte',
  },
  {
    name: 'Seguro de carga',
    url: '/seguro-carga',
  },
  {
    name: 'Requisitos',
    url: '/requisitos',
  },
  {
    name: 'Opciones de calificaciones',
    url: '/calificaciones/opciones',
  },
  {
    name: 'Valores de calificaciones',
    url: '/calificaciones/valores',
  },
  {
    name: 'Bancos',
    url: '/banco',
  },
  {
    name: rutas.tipos_cuenta.title,
    url: `${rutas.tipos_cuenta.slug}`,
  },
  {
    name: 'Términos y condiciones',
    url: '/terms-conditions',
  },
  {
    name: 'Publicaciones',
    url: '/publicacion',
  },
  {
    name: 'Geocercas',
    url: '/geocercas',
  },
  {
    name: 'Profit',
    url: '/profit',
  },
  {
    name: 'Impuestos',
    url: '/impuestos',
  },
  {
    name: 'Sugerencias de cotizaciones',
    url: '/sugerencias-cotizaciones',
  },
  {
    name: 'Requerimientos solicitud de viaje',
    url: '/exigencias-solicitud',
  },
  {
    name: 'Algoritmo de búsqueda de vehículos',
    url: '/busqueda-cliente-configuracion',
  },
  {
    name: 'Recursos restringidos',
    url: '/recursos-restringidos',
  },
]

const MenuConfig = () => {
  return (
    <nav aria-label='main mailbox folders'>
      {
        items.map((item, index) => {
          return (
            <Link
              to={item.url}
              style={{ textDecoration: 'none', color: 'black' }}
              key={index}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Link>
          )
        })
      }
      
    </nav>
  );
};

export default MenuConfig;
