import { LoadingButton } from "@mui/lab"
import { Alert, AlertTitle, Button, CircularProgress, debounce, FormControlLabel, FormGroup, Grid, MenuItem, Switch, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"
import { FaMinusCircle, FaPlus } from "react-icons/fa"
import SelectorZonaV2 from "../../subcomponents/maps/selector_geocerca_v2"
import { BsGeo } from "react-icons/bs"

const ConfigClientWeb = (props) => {
    const {
        propietario
    } = props
    const estructuraActual = {
        allow_show_photos_vehicles: false
    }
    const [ configuracion, setConfiguracion ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const dispatch      = useDispatch()
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const sesion        = useSelector((state) => state.miusuario)
    const showTitle     = props.showTitle ? props.showTitle : false
    const pais = useSelector(state => state.pais)
    const [ id, setId ] = useState("")
    const tipo          = "porta-cliente"
    const sub_tipo      = "configuracion-empresa"

    const handleChange = (e) => {
        const { name, value } = e.target
        return setConfiguracion(prev => {
            const actual = { ...prev }
            actual[name] = value
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const obtenerConfiguracion = async () => {
        setLoading(true);
        return fetch(`${urlapi}/configuracion/personalizar`, {
        method: 'POST',
        body: JSON.stringify({
            propietario,
            tipo,
            sub_tipo,
            detalles: estructuraActual
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
        },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then((res) => {
            console.log(res)
            if (!res) {
                toast.error('Sin datos');
                return setLoading(false);
            } else if (res.errorMessage) {
                toast.error(res.errorMessage);
                return setLoading(false);
            } else if (res._id) {
                setId(res._id)
                setConfiguracion(res.detalles)
            }
            return setLoading(false);
        })
        .catch((error) => {
            toast.error('Error al consultar la información, intente nuevamente');
            return setLoading(false);
        })
    }

    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }

    const handleChangeSwitch = (e, key) => {
        const { checked } = e.target
        return setConfiguracion(prev => {
            const actual = { ...prev }
            actual[key] = checked
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const handleChangeOption = (e, i, key = "disable_options_zones") => {
        const { name, value } = e.target
        console.log( value, i)
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual[key][i] = value
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const remover = (i, key = "disable_options_zones") => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual[key].splice(i, 1)
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }


    const mostrarOpciones = (key = "disable_options_zones") => {
        if(!configuracion[key]) return null
        if(!Array.isArray(configuracion[key])) return null
        const options = configuracion[key]
        return <div>
            {
                options.map((item, index) => {
                    return <div key={`opt-${index}`}>
                        <Grid container spacing={1} justifyItems="center" alignItems="center" alignContent="center">
                        <Grid item xs={10}>
                            
                        <SelectorZonaV2 defaultValue={item?.titulo ? item : null} onChange={data => handleChangeOption(data ? { target: { value: data ? data : null } } : null , index, )} />
                        {/* <TextField
                            label='Nombre de la sugerencia'
                            variant='filled'
                            name='value'
                            mb={2}
                            value={item.value}
                            fullWidth
                            onChange={(e) => handleChangeOption(e, index)}
                            /> */}
                        </Grid>
                        <Grid item xs={2}>
                        <Button color="error" startIcon={<FaMinusCircle />} onClick={() => remover(index)} >REMOVER</Button>
                        </Grid>
                        
                        </Grid>
                        </div>
                })
            }
        </div>
    }

    
    const agregarOpcion = (key = "disable_options_zones") => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            if(!actual[key]) actual[key] = []
            actual[key].push({ value: "" })
            return actual
        })
    }


    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    useEffect(() => {
        obtenerConfiguracion()
    }, [])

    if(loading) return <div><CircularProgress /></div>

    return <div>
        <Typography variant='h4' mt={0} ><b>Configuración en el portal cliente</b></Typography>
        <Typography >Configura en esta sección como interacturará esta empresa en el portal de clientes.</Typography>
        <Typography mb={2}>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>{ loadingGuardado ? <Alert severity="info"><b>GUARDANDO...</b></Alert> : <Alert severity="success"><b>GUARDADO</b></Alert> }</b> }</Typography>
        <Grid container spacing={1}>
            <Grid item md={3} xs={12} sx={{ mb: 3 }}>
                <FormGroup>
                  <FormControlLabel control={<Switch defaultChecked={configuracion.allow_show_photos_vehicles} onChange={(e) => handleChangeSwitch(e,'allow_show_photos_vehicles')} />} label="Permitir mostrar fotos de sus vehículos en la búsqueda" />
                </FormGroup>
              </Grid>
            <Grid item md={3} xs={12} sx={{ mb: 3 }}>
                <FormGroup>
                  <FormControlLabel control={<Switch defaultChecked={configuracion.allow_insurance} onChange={(e) => handleChangeSwitch(e,'allow_insurance')} />} label="Permitir agregar seguros en las cotizaciones" />
                </FormGroup>
              </Grid>
            <Grid item md={3} xs={12} sx={{ mb: 3 }}>
                <FormGroup>
                  <FormControlLabel control={<Switch defaultChecked={configuracion.avoid_express} onChange={(e) => handleChangeSwitch(e,'avoid_express')} />} label="No permitir disponibilidad inmediata" />
                </FormGroup>
              </Grid>
            <Grid item md={3} xs={12} sx={{ mb: 3 }}>
                <FormGroup>
                  <FormControlLabel control={<Switch defaultChecked={configuracion.allow_publish} onChange={(e) => handleChangeSwitch(e,'allow_publish')} />} label="Permitido para publicar en portal cliente" />
                </FormGroup>
              </Grid>
              
            <Grid item md={6} sx={{ mb: 3 }} xs={12}>
                <TextField
                  label='Valor máximo de carga permitido para transportar'
                  variant='filled'
                  name='maximun_cargo_valuation'
                  fullWidth
                  type="number"
                  helperText="Si modificas este campo afectará a todas las disponibilidades ya creadas, este cambio no se puede revertir"
                  defaultValue={configuracion.maximun_cargo_valuation}
                  onChange={handleChange}
                />
              </Grid>
            <Grid item md={6} sx={{ mb: 3 }} xs={12}>
                <TextField
                  label='Año mínimo del vehículo a disponibilizar'
                  variant='filled'
                  name='minimun_year'
                  fullWidth
                  type="number"
                  helperText="Si modificas este campo afectará a todas las disponibilidades ya creadas, este cambio no se puede revertir"
                  defaultValue={configuracion.minimun_year}
                  onChange={handleChange}
                />
              </Grid>
            <Grid item md={6} sx={{ mb: 3 }} xs={12}>
                <TextField
                  label='Cantidad máxima de disponibilidades a crear'
                  variant='filled'
                  name='maximun_availability'
                  fullWidth
                  type="number"
                  helperText="Si modificas este campo afectará a todas las disponibilidades ya creadas, este cambio no se puede revertir"
                  defaultValue={configuracion.maximun_availability}
                  onChange={handleChange}
                />
              </Grid>
            <Grid item md={12} sx={{ mb: 3 }} xs={12}>
                    <Typography variant="h6" ><BsGeo /> Zonas bloqueadas</Typography>
                    <Alert color="warning" sx={{ mb: 2 }} >Cuando agregues y selecciones una zona esto afectará a las disponibilidades creadas por esta empresa, este cambio no se podrá revertir</Alert>
                    <Button style={{ marginBottom: 10 }} variant="contained" color="primary" startIcon={<FaPlus />} onClick={() => agregarOpcion()} > AGREGAR ZONA BLOQUEADA</Button>
                    {mostrarOpciones()}
              </Grid>
            {/* 
            <Grid item md={6} xs={12}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label=""
                    sx={{ mb: 2 }}
                    fullWidth
                    name="maximun_cargo_valuation"
                    defaultValue={configuracion.maximun_cargo_valuation}
                    onChange={handleChange}
                    >
                    <MenuItem value="porcentaje">Porcentaje</MenuItem>
                    <MenuItem value="fijo">Monto fijo</MenuItem>
                </TextField>
            </Grid>
            <Grid item md={12} xs={12}>
              </Grid> */}
        </Grid>
    </div>
}

export default ConfigClientWeb