import { LoadingButton } from "@mui/lab"
import { Alert, Box, Button, Card, CardContent, CircularProgress, debounce, Grid, MenuItem, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"
import { FaMinusCircle, FaPlus, FaSort } from "react-icons/fa";
import ModalEmpresas from "../Empresas/modal_seleccion"
import { ReactSortable } from "react-sortablejs"
import ModalVehiculos from "../Vehicles/modal_seleccion"
import { obtenerIdentificadorVehiculo } from "../../lib/helpers/vehiculos"

const ListaRecursosRestringidos = (props) => {
    const estructuraActual = {
        options: []
    }
    const [ configuracion, setConfiguracion ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const dispatch      = useDispatch()
    const types_phase = {
        EMPRESAS: "EMPRESAS",
        VEHICULOS: "VEHICULOS",
    }
    const types_phase_titles = {
        // EMPRESAS: "Preferencia de empresas",
        VEHICULOS: "Vehículos restringidos",
    }
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const sesion        = useSelector((state) => state.miusuario)
    const showTitle     = props.showTitle ? props.showTitle : false
    const pais = useSelector(state => state.pais)
    const [ id, setId ] = useState("")
    const tipo          = "cliente_restricciones"
    const sub_tipo      = "recursos_bloqueados"

    const handleChange = (e) => {
        const { name, value } = e.target
        return setConfiguracion(prev => {
            const actual = { ...prev }
            actual[name] = value
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const obtenerConfiguracion = async () => {
        setLoading(true);
        return fetch(`${urlapi}/configuracion/personalizar`, {
        method: 'POST',
        body: JSON.stringify({
            tipo,
            sub_tipo,
            detalles: estructuraActual
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
        },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then((res) => {
            console.log(res)
            if (!res) {
                toast.error('Sin datos');
                return setLoading(false);
            } else if (res.errorMessage) {
                toast.error(res.errorMessage);
                return setLoading(false);
            } else if (res._id) {
                setId(res._id)
                setConfiguracion(res.detalles)
            }
            return setLoading(false);
        })
        .catch((error) => {
            toast.error('Error al consultar la información, intente nuevamente');
            return setLoading(false);
        })
    }

    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    useEffect(() => {
        obtenerConfiguracion()
    }, [])

    const handleChangeOption = (e, i) => {
        const { name, value } = e.target
        console.log(name, value, i)
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.options[i][name] = value
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const remover = (i) => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.options.splice(i, 1)
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const reordenarItems = (data, pos) => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.options[pos].values = data
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }
    const mostrarOpciones = (item, i, key) => {
        if(!item.values) return null
        if(!Array.isArray(item.values)) return null

        const key_label = key === "empresa" ? (e) => e.razon_social : (e) => obtenerIdentificadorVehiculo(e)

        return <div>

        <ReactSortable list={item.values} setList={(d) => reordenarItems(d, i)}>
        {item.values.map((registro, index) => {
                        return <Box mb={3} key={`${key}-${index}`}>
                            <Card >
                            <CardContent>
                            <Grid container spacing={1} justifyItems="center" alignItems="center" alignContent="center">
                                <Grid item xs={10}>
                                <Typography variant="h6" className="hover" style={{ textTransform: "uppercase" }}><FaSort /> {key_label(registro)}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                <Button color="error" startIcon={<FaMinusCircle />} onClick={() => {
                                    setConfiguracion(prev => {
                                        let actual = { ...prev }
                                        actual.options[i].values.splice(index, 1)
                                        actualizar({ _id: id, detalles: actual })
                                        return actual
                                    })
                                } } >REMOVER</Button>
                                </Grid>
                                
                                </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    })}
        </ReactSortable>
            
        </div>
    }

    const selectorVehiculos = (item, i) => {
        return <div>
            <Typography variant="h5">{i+1} <b>{types_phase_titles.VEHICULOS}</b> <Button color="error" startIcon={<FaMinusCircle />} onClick={() => remover(i)} >REMOVER</Button></Typography>
            <Alert severity="info">Los siguientes vehículos no podrán participar en las plataformas asociadas</Alert>
            <div style={{ marginBottom: 20, marginTop: 20 }} >
            <ModalVehiculos titulo="AGREGAR VEHÍCULO" seleccionarVehiculo={(data) => {
                setConfiguracion(prev => {
                    let actual = { ...prev }
                    if(!actual.options[i].values) actual.options[i].values = []
                    actual.options[i].values.unshift(data)
                    actualizar({ _id: id, detalles: actual })
                    return actual
                })
            }} />
            </div>
            {mostrarOpciones(item, i, 'vehiculo')}
        </div>
    }


    const selectorEmpresas = (item, i) => {
        return <div>
            <Typography variant="h5">{i+1} <b>{types_phase_titles.EMPRESAS}</b> <Button color="error" startIcon={<FaMinusCircle />} onClick={() => remover(i)} >REMOVER</Button></Typography>
            <Alert severity="info">Puedes agregar varias empresas que tendrán la prioridad, el orden de ellas impactará también en la preferencia</Alert>
            <div style={{ marginBottom: 20, marginTop: 20 }} >
            <ModalEmpresas titulo="AGREGAR EMPRESA" seleccionarEmpresa={(data) => {
                setConfiguracion(prev => {
                    let actual = { ...prev }
                    if(!actual.options[i].values) actual.options[i].values = []
                    actual.options[i].values.unshift(data)
                    actualizar({ _id: id, detalles: actual })
                    return actual
                })
            }} />
            </div>
            {mostrarOpciones(item, i, 'empresa')}
        </div>
    }

    const componentByType = (item, i) => {
        const type = item?.type
        switch (type) {
            case types_phase.EMPRESAS:
                return selectorEmpresas(item,i)
            case types_phase.VEHICULOS:
                return selectorVehiculos(item,i)
            default:
                break;
        }
    }

    const mostrarSugerencias = () => {
        if(!configuracion.options) return null
        if(!Array.isArray(configuracion.options)) return null
        return <div>
            {
                configuracion.options.map((item, index) => {
                    return <div key={`opt-${index}`}>
                        {componentByType(item, index)}
                        </div>
                })
            }
        </div>
    }

    const agregarOpcion = (type) => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            const i = actual.options.findIndex(e => e.type === type)
            if(i >= 0) return actual
            actual.options.push({ type, value: "" })
            return actual
        })
    }

    if(loading) return <div><CircularProgress /></div>

    const disabledBusiness = configuracion.options.find(e => e.type === types_phase.EMPRESAS) ? true : false
    const disabledVehicles = configuracion.options.find(e => e.type === types_phase.VEHICULOS) ? true : false

    return <div>
        <Typography variant='h4' mt={0} ><b>Algoritmo de búsqueda portal cliente</b></Typography>
        <Typography >En esta sección podrás restringir recursos para que no participen en ningúna de las plataformas asociadas</Typography>
        <Typography mb={2}>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>{ loadingGuardado ? <Alert severity="info"><b>GUARDANDO...</b></Alert> : <Alert severity="success"><b>GUARDADO</b></Alert> }</b> }</Typography>
        <Grid container spacing={1}>
            
            <Grid item xs={12}>
            {/* <Button disabled={disabledBusiness} style={{ marginBottom: 10, marginRight: 10 }} variant="contained" color="primary" startIcon={<FaPlus />} onClick={() => agregarOpcion(types_phase.EMPRESAS)} > AGREGAR PREFERENCIA DE EMPRESAS</Button> */}
            <Button disabled={disabledVehicles} style={{ marginBottom: 10, marginRight: 10 }} variant="contained" color="primary" startIcon={<FaPlus />} onClick={() => agregarOpcion(types_phase.VEHICULOS)} > AGREGAR DE VEHÍCULOS</Button>
            {mostrarSugerencias()}
              </Grid>
            <Grid item md={12} xs={12}>
            {/* <LoadingButton
                    sx={{ width: "100%" }}
                    loading={loadingGuardado}
                    loadingPosition="start"
                    variant="outlined"
                    color="success"
                    onClick={() => actualizarConfiguracion()}
                    >
                     GUARDAR CAMBIOS
                </LoadingButton> */}
              </Grid>
        </Grid>
    </div>
}

export default ListaRecursosRestringidos